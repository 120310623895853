<template>
  <div class="px-8" id="Portafolio">
    <v-row class="oculto ttb">
      <v-col cols="12">
        <h2 class="text-center mt-4">Portafolio</h2>
        <p class="text-center my-4">
          Estos son algunos de los proyectos en los que he trabajado y/o
          colaborado
        </p>
      </v-col>
    </v-row>
    <v-row class="btt oculto">
      <v-col cols="12" class="py-0">
        <h2 class="text-center">Proyectos Personales:</h2>
      </v-col>
    </v-row>
    <v-divider />
    <v-row justify="center" align="center" class="mb-4">
      <v-col cols="12" md="6" class="oculto ltr">
        <h2>SOFIA - Versión Alfa</h2>
        <v-row justify="center" align="center">
          <v-col cols="12" class="py-0">
            <h3>Información del Proyecto</h3>
            <v-divider />
            <p>
              <b>SOFIA - Software Interactivo de Alerta</b> La violencia en
              contra de la mujer ha tenido un gran aumento en los últimos años,
              es común que ninguna mujer se sienta segura al momento de
              transitar en la vía pública.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" align="start">
          <v-col cols="12" md="6" class="py-0">
            <h3>Solución</h3>
            <v-divider />
            <p>
              Es una aplicación web que busca reducir todos aquellos incidentes
              que puedan afectar la integridad física y psicológica de la mujer.
              Mediante el uso de
              <b>
                tecnologías de localización y a partir de reportes de las
                usuarias
              </b>
              se busca mapear las zonas inseguras de la ciudad para que las
              usuarias puedan elegir rutas alternas donde se sientan más
              seguras.
            </p>
            <v-btn
              block
              dark
              @click="goToPage('https://sofia-app.com/')"
              class="mb-4"
            >
              Ir Al Sitio
              <v-icon right>mdi-play</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <h3>Tecnologías Utilizadas</h3>
            <v-divider />
            <v-list dense>
              <v-list-item
                v-for="(item, index) in sofiaTecnologies"
                :key="index"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="oculto rtl">
        <v-carousel
          v-if="!$vuetify.breakpoint.smAndDown"
          hide-delimiters
          cycle
          height="55vh"
          show-arrows-on-hover
          interval="5000"
        >
          <v-carousel-item
            v-for="(item, i) in sofiaImages"
            :key="i"
            :src="item.src"
            reverse-transition="slide-x-transition"
            transition="slide-x-transition"
          >
          </v-carousel-item>
        </v-carousel>
        <v-img
          v-else
          v-for="(item, index) in sofiaImages"
          :key="index"
          :src="item.src"
          class="mb-2"
        ></v-img>
      </v-col>
    </v-row>
    <!-- En colaboracion -->
    <v-row class="btt oculto">
      <v-col cols="12" class="py-0">
        <h2 class="text-center">En Colaboración Con:</h2>
        <v-divider />
        <a href="https://conviertes.com" target="_blank">
          <v-img
            src="@/assets/images/logo_conviertes.png"
            height="15vh"
            contain
            class="mt-4"
          ></v-img>
        </a>
      </v-col>
    </v-row>
    <!-- Wissen -->
    <v-row justify="center" align="center" class="mb-4">
      <v-col
        cols="12"
        md="6"
        :order="$vuetify.breakpoint.smAndDown ? 2 : 1"
        class="oculto ltr"
      >
        <v-carousel
          v-if="!$vuetify.breakpoint.smAndDown"
          hide-delimiters
          cycle
          height="55vh"
          show-arrows-on-hover
          interval="5000"
        >
          <v-carousel-item
            v-for="(item, i) in iiwImages"
            :key="i"
            :src="item.src"
            reverse-transition="slide-x-transition"
            transition="slide-x-transition"
          >
          </v-carousel-item>
        </v-carousel>
        <v-img
          v-else
          v-for="(item, index) in iiwImages"
          :key="index"
          :src="item.src"
          class="mb-2"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :order="$vuetify.breakpoint.smAndDown ? 1 : 2"
        class="oculto rtl"
      >
        <h2>Instituto Internacional Wissenschaftler</h2>
        <v-row justify="center" align="center">
          <v-col cols="12" class="py-0">
            <h3>Información del Cliente</h3>
            <v-divider />
            <p>
              <b>Instituto Internacional Wissenschaftler</b> es un colegio
              privado en San Luis Potosí con un sistema de educación bilingüe.
              Ofrece los niveles de maternal, preescolar, primaria, a través de
              programas académicos de alto nivel de calidad.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" align="start">
          <v-col cols="12" md="6" class="py-0">
            <h3>Solución</h3>
            <v-divider />
            <p>
              Se desarrolló una página web responsiva así como un sistema web
              utilizando las mejores tecnologías para la administración de
              <b>
                usuarios, grupos, materias, maestros, tareas y calificaciones.
              </b>
            </p>
            <v-btn block dark @click="goToPage('http://iiw.mx')" class="mb-4">
              Ir Al Sitio
              <v-icon right>mdi-play</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <h3>Tecnologías Utilizadas</h3>
            <v-divider />
            <v-list dense>
              <v-list-item v-for="(item, index) in iiwTecnologies" :key="index">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <!-- UTSLP -->
    <v-row justify="center" align="center" class="mb-4">
      <v-col
        cols="12"
        md="6"
        :order="$vuetify.breakpoint.smAndDown ? 2 : 1"
        class="oculto ltr"
      >
        <v-img :src="require('@/assets/images/ut.png')"></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :order="$vuetify.breakpoint.smAndDown ? 1 : 2"
        class="oculto rtl"
      >
        <h2>Universidad Tecnológica de San Luis Potosí - UTSLP</h2>
        <v-row justify="center" align="center">
          <v-col cols="12" class="py-0">
            <h3>Información del Cliente</h3>
            <v-divider />
            <p>
              <b>Universidad Tecnológica de San Luis Potosí - UTSLP</b> es una
              univerdad de caracter privado, que ofrece carreras de Técnico
              Superior Universitario (TSU) así como títulos de ingeniería y
              licenciatura.
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" align="start">
          <v-col cols="12" md="6" class="py-0">
            <h3>Solución</h3>
            <v-divider />
            <p>
              Se desarrolló una página web responsiva para mostrar toda la
              información de la universidad siguiendo el paradigma
              <b>SPA - Single Page Application</b>
            </p>
            <v-btn
              block
              dark
              @click="goToPage('https://utslp.com.mx/')"
              class="mb-4"
            >
              Ir Al Sitio
              <v-icon right>mdi-play</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <h3>Tecnologías Utilizadas</h3>
            <v-divider />
            <v-list dense>
              <v-list-item v-for="(item, index) in utTecnologies" :key="index">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Portafolio",
  mounted: function() {
    this.addEvents();
  },
  methods: {
    addEvents: function() {
      document.addEventListener("scroll", this.animations);
      document.addEventListener("wheel", this.animations);
      document.addEventListener("touchmove", this.animations);
    },
    animations: function() {
      var cuerpo = document.querySelector("html");
      var topToBottom = document.querySelectorAll(".ttb");
      var btt = document.querySelectorAll(".btt");
      var ltr = document.querySelectorAll(".ltr");
      var rtl = document.querySelectorAll(".rtl");
      var topVent = cuerpo.scrollTop;
      for (let i = 0; i < topToBottom.length; i++) {
        let topElement = topToBottom[i].offsetTop;
        if (topVent > topElement - 500) {
          topToBottom[i].classList.add("topToBottom");
        }
      }
      for (let i = 0; i < btt.length; i++) {
        let topElement = btt[i].offsetTop;
        if (topVent > topElement - 500) {
          btt[i].classList.add("bottomToTop");
        }
      }
      for (let i = 0; i < ltr.length; i++) {
        let topElement = ltr[i].offsetTop;
        if (topVent > topElement - 500) {
          ltr[i].classList.add("izquierdaDerecha");
        }
      }
      for (let i = 0; i < rtl.length; i++) {
        let topElement = rtl[i].offsetTop;
        if (topVent > topElement - 500) {
          rtl[i].classList.add("derechaIzquierda");
        }
      }
    },
    goToPage: function(url) {
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    }
  },
  data: function() {
    return {
      sofiaImages: [
        {
          src: require("@/assets/images/sofia/inicio.png")
        },
        {
          src: require("@/assets/images/sofia/login.png")
        },
        {
          src: require("@/assets/images/sofia/dashboard.png")
        },
        {
          src: require("@/assets/images/sofia/mapa.png")
        },
        {
          src: require("@/assets/images/sofia/contactos.png")
        }
      ],
      sofiaTecnologies: [
        {
          icon: "mdi-vuejs",
          title: "Vue.js"
        },
        {
          icon: "mdi-vuetify",
          title: "Vuetify"
        },
        {
          icon: "mdi-firebase",
          title: "Firebase"
        }
      ],
      iiwImages: [
        {
          src: require("@/assets/images/iiwWeb.png")
        },
        {
          src: require("@/assets/images/iiwLogin.png")
        },
        {
          src: require("@/assets/images/iiwApp.png")
        }
      ],
      iiwTecnologies: [
        {
          icon: "mdi-vuejs",
          title: "Vue.js"
        },
        {
          icon: "mdi-vuetify",
          title: "Vuetify"
        },
        {
          icon: "mdi-wordpress",
          title: "WordPress"
        },
        {
          icon: "mdi-firebase",
          title: "Firebase"
        },
        {
          icon: "mdi-language-php",
          title: "Php"
        }
      ],
      inmoTecnologies: [
        {
          icon: "mdi-vuejs",
          title: "Vue.js"
        },
        {
          icon: "mdi-vuetify",
          title: "Vuetify"
        },
        {
          icon: "mdi-firebase",
          title: "Firebase"
        }
      ],
      inmoImages: [
        {
          src: require("@/assets/images/inmoWeb.png")
        },
        {
          src: require("@/assets/images/inmoWebDetails.png")
        },
        {
          src: require("@/assets/images/inmoLogin.png")
        },
        {
          src: require("@/assets/images/inmoApp.png")
        }
      ],
      utTecnologies: [
        {
          icon: "mdi-vuejs",
          title: "Vue.js"
        },
        {
          icon: "mdi-vuetify",
          title: "Vuetify"
        },
        {
          icon: "mdi-firebase",
          title: "Firebase"
        }
      ]
    };
  }
};
</script>
